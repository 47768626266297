.username-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.edit-icon-profile {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  background-color: red;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
