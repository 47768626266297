.container {
  overflow-x: scroll;
  display: flex;
  text-align: justify;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 15px;
  /* justify-content: center; */
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
  display: none;
}

.box {
  margin: 1rem;
}
